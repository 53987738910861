export const loadGoogleAnalytics = () => {
  if (typeof window !== "undefined" && !window.gatsbyPluginGoogleGtagLoaded) {
    window.gatsbyPluginGoogleGtagLoaded = true;

    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-KG3VHZSHY0`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-KG3VHZSHY0', {
        anonymize_ip: true,
        cookie_expires: 0,
        respectDNT: true
      });
    };
  }
};
