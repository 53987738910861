/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'
import { loadGoogleAnalytics } from "./src/utils/loadGoogleAnalytics";
import { loadClarity } from "./src/utils/loadClarity";

export const shouldUpdateScroll = ({
           routerProps: { location }
    }) => {
    if (location.hash) {
        window.setTimeout(() => {
            const anchor = document.querySelector(location.hash);
            if (anchor) {
                let offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
                const offset = 152;
                window.scrollTo({
                    top: offsetTop - offset,
                    behavior: 'smooth'
                });
            }
        }, 0);
    }

    return true;
};

export const onClientEntry = () => {
    const storedConsent = JSON.parse(localStorage.getItem("cookieConsent"));
    if (storedConsent) {
        if (storedConsent.analytics) {
          loadGoogleAnalytics();
        }
        if (storedConsent.clarity) {
            loadClarity();
        }
    }
};